.main-carousel {
  margin-top: 10px;
  margin-bottom: 10px;
  .swiper-slide img {
    border-radius: 10px;
  }
  .swiper-pagination {
    bottom: 4px;
  }
}

// Benefits
.s-benefits {
  &__item {
    border-radius: 10px;
    padding: 16px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  &__image {
    margin-bottom: 8px;
  }
  &__title {
    margin-bottom: 10px;
    font-weight: 700;
    font-size: 14px;
  }
  &__text {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #9c9aa7;
  }
  @include media-breakpoint-up(md) {
    &__item {
      padding: 20px;
    }
    &__image {
      margin-bottom: 10px;
    }
    &__title {
      font-size: 15px;
    }
    &__text {
      font-size: 14px;
      line-height: 18px;
    }
  }
}

// Articles
.s-articles {
  .card {
    position: relative;
  }
  &__categories {
    position: absolute;
    right: 20px;
    top: 16px;
    z-index: 12;
  }
  &__category {
    display: block;
    border-radius: 50px;
    padding: 8px 15px;
    background-color: #fff;
    font-size: 14px;
    font-weight: 700;
    color: $secondary;
    line-height: 1;
    text-decoration: none;
  }
  &__category + &__category {
    margin-top: 4px;
  }
  &__title {
    display: block;
    min-height: 48px;
    margin-top: 0.625rem;
    margin-bottom: 1rem;
    font-weight: 500;
    font-size: 16px;
    line-height: 1;
    text-decoration: none;
    &:hover {
      color: $gray-600;
    }
  }

  .card-text {
    font-weight: 400;
    font-size: 14px;
    color: $gray-800;
    line-height: 18px;
  }
  .card-link {
    font-weight: 500;
    font-size: 17px;
    color: $secondary;
    text-decoration: none;
    @include transition($btn-transition);
    &:hover {
      color: $gray-700;
    }
  }
}

// Contact page
.s-contact {
  &__inner {
    display: flex;
    color: $gray-700;
    font-size: 16px;
    font-weight: 600;
  }
  &__title {
    font-weight: 800;
    font-size: 16px;
    margin-bottom: 0.5rem;
  }
  &__icon {
    margin-top: 0.15rem;
    margin-right: 0.5rem;
    color: $primary;
    font-size: 20px;
    width: 1.25em;
    text-align: center;
  }

  &__link.custom-link {
    padding-bottom: 0;
    color: $gray-700;

    &:before {
      bottom: 0.125rem;
    }
  }
}

.carouselVideos {
  &::after {
    content: "";
    display: block;
    width: 20%;
    height: 2px;
    background-color: $secondary;
    position: absolute;
    left: 0;
    bottom: -24px;
  }
}
