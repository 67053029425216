// Variables
$enable-caret: false;
$enable-rounded: true;
$enable-shadows: true;
$enable-smooth-scroll: false;

// Colors
$green: #26b758;
$primary: #fecb0e;
$secondary: #0a96f8;
$danger: #f02323;
$gray-100: #f7f8f8;
$gray-300: #efefef;
$gray-400: #d1d1d1;
$gray-500: #aaaaaa;
$gray-600: #545861;
$gray-700: #3d3d3d;
$gray-800: #282828;
$gray-900: #111111;
$light: $gray-100;
// $primary-text-emphasis: #0a2f53;
// $primary-bg-subtle: #d1e3f5;
// $primary-border-subtle: #a3c8ec;
// $link-hover-color: #155ea6;
$dark: $gray-800;

// Ratio
$min-contrast-ratio: 2;

// Fonts
$font-family-sans-serif: "Montserrat", sans-serif;
$font-size-base: 0.875rem;

$h1-font-size: 1.675rem;
$h2-font-size: 1.5rem;
$h3-font-size: 1.375rem;
$h4-font-size: 1.25rem;
$h5-font-size: 1.125rem;
$h6-font-size: 1rem;

$border-radius: 0.625rem;
$border-radius-sm: 0.375rem;

// Button
$input-btn-padding-y: 0.5rem;
$input-btn-padding-x: 1.5rem;
$btn-padding-y: 0.625rem;
$btn-padding-y-lg: 0.625rem;
$btn-padding-x-lg: 2rem;
$btn-font-weight: 600;
$btn-line-height: 1.375;
$btn-box-shadow: none;

// Breadcrumb
$breadcrumb-margin-bottom: 0;
$breadcrumb-font-size: 12px;
$breadcrumb-item-padding-x: 0.625rem;

// Input
$form-label-font-weight: 600;
$input-padding-x: 1rem;
$input-bg: $gray-300;
$input-border-color: $gray-300;
$input-font-weight: 300;
$input-color: $gray-800;
$input-line-height: 1.325;

$input-placeholder-color: $gray-700;

// Modal
$modal-content-border-radius: 1rem;

// Card
$card-border-radius: 0;
$card-img-overlay-padding: 0;
$card-border-width: 0;
$card-border-color: $gray-100;
$card-title-spacer-y: 0.625rem;
$card-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.22);

// Shadow
$box-shadow: 0px 0px 0.675rem 0px rgba(0, 0, 0, 0.07);
$box-shadow-hover: 0px 0px 0.375rem 0px rgba(0, 0, 0, 0.05);

// Grid
$grid-gutter-width: 1.25rem;
