.s-thumbnail {
  max-width: 100%;
  @include media-breakpoint-up(xl) {
    &__thumbs {
      width: 80%;
    }
    &__images {
      width: 20%;
    }
  }
  &__thumb,
  &__image {
    text-align: center;
    padding: 6px;
    @include media-breakpoint-up(md) {
      padding: 10px;
    }
  }
  @include media-breakpoint-up(md) {
    margin-left: -10px;
  }
  img {
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.07);
  }
  &__thumbs {
    .s-products__labels {
      flex-direction: column;
      margin-left: auto;
      text-align: center;
      left: auto;
      top: 20px;
      right: 20px;
      align-items: flex-end;
    }
    img {
      width: auto;
      max-height: 100%;
    }
  }
}
.img-fluid-height {
  width: auto;
  max-height: 100%;
}

.custom-tabs {
  .nav-item + .nav-item {
    margin-left: 20px;
  }
  .nav-link {
    border: 1px solid $gray-300;
    border-radius: 5px;
    padding: 5px 15px;
    font-weight: 500;
    font-size: 14px;
    color: $gray-800;
    line-height: 15px;
    &.active {
      font-weight: bold;
    }
  }
}

.rating {
  display: flex;
  &__star {
    position: relative;
    font-size: 12px;
    color: #bdbdbd;
  }
  &__star + &__star {
    margin-left: 1px;
  }
  &__yellow {
    color: $warning;
  }
  &__star-half {
    position: absolute;
    left: 0;
    top: 0;
    width: 50%;
    color: $warning;
    overflow: hidden;
  }
}

.s-product {
  &__model {
    font-size: 13px;
    line-height: 15px;
  }
  &__manufacturer {
    font-size: 13px;
    color: $gray-900;
    line-height: 15px;
    a {
      font-weight: 700;
      color: $gray-900;
      text-decoration: none;
      &:hover {
        color: $secondary;
      }
    }
  }
}

.product-price {
  line-height: 1;
  &__old {
    font-weight: 400;
    font-size: 16px;
    color: $gray-500;
    text-decoration: line-through;
  }
  &__main {
    font-weight: 700;
    font-size: 22px;
    color: $secondary;
  }
  &__diff {
    font-size: 13px;
    color: #fff;
  }
}
.btn-product-link {
  display: inline-flex;
  align-items: center;
  font-weight: 300;
  font-size: 13px;
  color: #131313;
  text-decoration: none;
  .icon {
    margin-top: -2px;
    margin-bottom: -2px;
    margin-right: 8px;
    width: 21px;
    height: 21px;
    font-size: 21px;
    color: $primary;
    line-height: 21px;
    text-align: center;
  }
}

.product-info {
  &__title {
    font-weight: 600;
    font-size: 16px;
  }
  &__list {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }
  &__img {
    margin-right: 0.75rem;
  }
  &__icon {
    width: 1.25em;
    margin-right: 0.5rem;
  }
  li {
    display: flex;
    align-items: center;
    margin-top: 4px;
    padding-bottom: 6px;
    font-weight: 500;
    font-size: 14px;
    line-height: 15px;
  }
}
