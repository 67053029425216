/* Account */
.s-account {
  &__btn {
    padding: 10px 30px;
    font-weight: 500;
    font-size: 14px;
    min-width: 200px;
  }

  /* Login */
  &__deliver {
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    font-weight: 600;
    &::before,
    &::after {
      content: "";
      width: 30px;
      height: 1px;
      background-color: $gray-600;
      margin: 0 10px;
    }
  }
}

.required .text-danger,
.s-link-forgotten {
  display: block;
  margin-top: 3px;
  font-size: 13px;
}

.s-accountLinks .btn {
  display: block;
  padding-left: 15px;
  padding-right: 15px;
  font-size: 14px;
  text-align: left;
}
