// .container {
//   &,
//   &-xxl {
//     max-width: 1430px;
//   }
// }

.form-control {
  height: 38px;
}

textarea.form-control {
  height: auto;
}

// Lozad
.lozad.fade {
  opacity: 1;
  transition: opacity 0.15s ease-out;
  &:not([data-loaded="true"]) {
    opacity: 0;
  }
}

.link-dotted {
  text-decoration: none;
  border-bottom: 1px dotted;
  @include transition($input-transition);
  &:hover {
    border-color: transparent;
  }
}

.section-products {
  margin: 0 -10px;
  overflow: hidden;
  .swiper-viewport {
    padding: 10px;
    overflow: hidden;
  }
  .swiper-container {
    overflow: visible;
  }
}

.shadow-hover {
  @include transition($navbar-toggler-transition);
  &:hover {
    box-shadow: $box-shadow-hover !important;
  }
}

.swiper-button-prev,
.swiper-button-next {
  color: $primary;
}

// Card
.card-date {
  font-weight: 600;
  font-size: 12px;
  color: $gray-400;
  line-height: 1;
  .icon {
    font-size: 14px;
  }
}

// Pagination
.page-link svg {
  margin-top: -3px;
}

.clearfix {
  @include clearfix;
}

.nav-scroller {
  position: relative;
  z-index: 2;
  height: 2.75rem;
  overflow-y: hidden;
  .nav,
  .breadcrumb {
    display: flex;
    flex-wrap: nowrap;
    padding-bottom: 1rem;
    margin-top: -1px;
    overflow-x: auto;
    text-align: center;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
  }
}

// Mobile menu
.s-mMenu {
  &__link {
    display: block;
    font-weight: 600;
    font-size: 14px;
    color: $gray-800;
    line-height: 1.25;
    text-decoration: none;
  }
  &__telephone {
    font-weight: 700;
    font-size: 16px;
    color: $secondary;
    line-height: 1.25;
    text-decoration: none;
  }
  .s-lang {
    background-color: $gray-300;
    padding: 4px 0;
    &__select {
      padding: 2px 8px;
      font-weight: 700;
      color: $gray-800;
      line-height: 1;
      &.active {
        background-color: #fff;
        opacity: 1;
      }
    }
    &__item::before {
      border-color: $gray-800;
    }
  }
}

// Float row
.custom-row {
  margin-left: -12px;
  margin-right: -12px;
  > * {
    padding-left: 12px;
    padding-right: 12px;
  }
}
.custom-row__left {
  clear: left;
}

.rounded-sm {
  border-radius: $border-radius-sm;
}
