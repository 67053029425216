// Welcome section
.welcome-text {
  background-color: $white;
  padding: 4px;
}

.s-header {
  &__main {
    background-color: $gray-800;
  }
  &__bottom {
    background-color: $gray-700;
    padding: 0.5rem 0;
    .form-control {
      background-color: $gray-600;
      border-color: $gray-600;
      color: $white;
      &::placeholder {
        color: $white;
      }
    }
  }
  .btn {
    line-height: 1;
  }
  &__icons {
    display: flex;
    text-align: center;
    li {
      flex-grow: 1;
    }
  }
  &__icon {
    font-size: 22px;
    text-decoration: none;
    position: relative;
  }
  &__counter {
    display: none;
    position: absolute;
    right: calc(50% - 1.375rem);
    top: -0.5rem;
    background: $light;
    border-radius: 1rem;
    min-width: 1rem;
    height: 1rem;
    font-weight: 700;
    font-size: 9px;
    color: $gray-900;
    line-height: 1rem;
    &.bg-primary {
      color: $white;
    }
  }
  &__active &__counter {
    display: block;
  }

  &__link {
    font-size: 14px;
    font-weight: 500;
    color: $white;
    line-height: 15px;
    text-decoration: none;
    transition: $btn-transition;
    &:hover {
      color: $primary;
    }
  }
  &__link + &__link {
    margin-left: 20px;
    @include media-breakpoint-up(xl) {
      margin-left: 40px;
    }
  }
  &__link-contrast {
    font-weight: 600;
    font-size: 15px;
    color: $primary;
    &:hover {
      color: $white;
    }
  }

  // Catalog
  &__catalog {
    display: none;
    width: 100%;
    align-items: center;
    white-space: nowrap;
    text-align: left;
    text-decoration: none;
    padding: 0.5rem;

    @include media-breakpoint-up(lg) {
      display: flex;
    }

    span {
      margin-left: 0.25rem;
      font-weight: 600;
      font-size: 13px;
      color: $white;
      text-transform: uppercase;
      line-height: 1;
    }

    .icon {
      flex-shrink: 1;
      margin-bottom: -2px;
      font-size: 27px;
      width: 1.25em;
    }
  }

  &__dropdown-menu {
    border: 0;
    @include media-breakpoint-up(lg) {
      margin-top: 0.5rem !important;
      > nav {
        max-height: calc(100vh - 140px);
        height: 100%;
        width: 310px;
        overflow: hidden;
        overflow-y: auto;
      }
    }

    @include media-breakpoint-down(lg) {
      display: block;
      position: static;
    }
  }

  &__telephone {
    display: flex;
    align-items: center;
    margin-left: 2rem;
    .svg-icon-phone {
      fill: $white;
      width: 1rem;
      height: 1rem;
      margin-right: 0.5rem;
    }
    a {
      font-weight: 500;
      font-size: 16px;
      color: $white;
      line-height: 15px;
      text-decoration: none;
      transition: $nav-link-transition;
      &:hover {
        color: $primary;
      }
    }
  }

  &__iconNav {
    font-size: 27px;
    text-decoration: none;
  }
}

@include media-breakpoint-down(lg) {
  .s-header {
    &__main {
      position: relative;
      z-index: 5;
    }
    &__bottom {
      position: relative;
      z-index: -1;
      transition: all 0.3s;
      height: 54px;
      margin-top: -54px;
      opacity: 0;
      visibility: hidden;
    }
  }
  .showSerach .s-header__bottom {
    margin-top: 0;
    z-index: 1;
    opacity: 1;
    visibility: visible;
  }
}

// Language
.s-lang {
  &__select {
    font-weight: 500;
    font-size: 14px;
    color: $white;
    line-height: 15px;
    text-decoration: none;
    text-transform: uppercase;

    &:hover,
    &.active {
      opacity: 0.75;
    }
  }

  &__item + &__item::before {
    content: "";
    display: inline-block;
    border-left: 2px dotted $white;
    position: relative;
    left: -0.25rem;
    height: 5px;
  }
}

/* Header cart */
.modal-header-cart .cart-image {
  width: 90px;
}

.modal-header-cart .cart-name a {
  font-size: 13px;
  color: $black;
  display: block;
  line-height: 1.2;
  text-decoration: none;
}

.modal-header-cart .cart-name a:hover {
  color: $gray-600;
}

.cart-options {
  margin-top: 0.25rem;
  font-size: 13px;
  font-weight: 400;
  color: $gray-600;
  line-height: 1;
}

.modal-header-cart .btn-quantity {
  display: block;
  background-color: $primary;
  color: $white;
  border-radius: 0;
}

.modal-header-cart .btn-quantity:hover,
.modal-header-cart .btn-quantity:focus,
.modal-header-cart .btn-quantity:active:focus {
  background-color: $gray-600;
  outline: none;
}

.modal-header-cart .cart-quantity .form-control {
  max-width: 60px;
}

.modal-header-cart .cart-price,
.modal-header-cart .cart-total {
  color: $primary;
  white-space: nowrap;
}

.modal-header-cart .cart-total-price {
  font-weight: 800;
  font-size: 16px;
}

.modal-header-cart .text-muted {
  line-height: 1;
  font-weight: 700;
  font-size: 14px;
}

.modal-header-cart .cart-remove .btn {
  padding: 0;
  display: block;
  font-size: 18px;
  line-height: 1;
  margin: auto;
  color: #e64040;
  text-decoration: none;
}

.modal-header-cart .cart-remove .btn:hover {
  text-decoration: none;
  color: #bb4b4b;
}

.cart-result-total {
  font-size: 17px;
  font-weight: 400;
}

.cart-result-total strong {
  font-size: 20px;
  margin-left: 12px;
}

@include media-breakpoint-up(lg) {
  .modal-header-cart .modal-footer {
    padding-top: 15px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }

  .modal-header-cart .modal-footer .btn {
    margin: 0 10px;
    min-width: 230px;
    font-size: 13px;
  }

  .modal-header-cart .modal-footer .btn[data-dismiss="modal"] {
    margin-right: auto;
  }
}

@include media-breakpoint-down(sm) {
  .modal-header-cart .modal-body {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@include media-breakpoint-down(lg) {
  .modal-header-cart .table td {
    display: block;
    padding-top: 15px;
    padding-bottom: 15px;
    border: 0;
  }

  .modal-header-cart .table td.cart-image {
    width: 20%;
  }

  .modal-header-cart .table tr {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: relative;
  }

  .modal-header-cart .cart-name {
    display: block;
    width: 70%;
  }

  .modal-header-cart .cart-total {
    width: 55%;
    margin-left: auto;
  }

  .modal-header-cart .cart-quantity {
    width: 45%;
  }

  .modal-header-cart .table td.cart-remove {
    width: 10%;
  }

  .cart-result-total .text-right {
    text-align: center;
  }

  .modal-header-cart .modal-footer .btn {
    display: block;
    width: 100%;
  }

  .modal-header-cart .modal-footer .btn + .btn {
    margin-left: 0;
    margin-top: 10px;
  }
}

.empty-cart {
  padding: 15px;
  margin: 0;

  &-icon {
    font-size: 100px;
    line-height: 50px;
    color: $gray-500;
  }
}

/* Search autocomplete */
.top-serach {
  z-index: 5;
  max-width: 100%;
  margin-left: auto;
  > .btn {
    padding: 5px;
    height: 100%;
    font-size: 26px;
    color: $primary;
    z-index: 10;
    box-shadow: none;
    border-color: transparent;
    &:active {
      color: $white;
      border-color: transparent;
    }
  }
  .dropdown-menu {
    width: 100%;
    > li + li {
      border-top: 1px solid $gray-300;
    }
    > li > a {
      display: block;
      padding: 0.25rem 0.75rem;
      text-decoration: none;
      &:hover {
        background-color: $gray-100;
      }
    }
  }
  @include media-breakpoint-up(lg) {
    max-width: 390px;
  }
}

.search-item {
  display: flex;
  align-items: center;

  &__content {
    display: block;
    padding-left: 10px;
    line-height: 1;
    white-space: normal;
  }
  &__name {
    display: block;
    margin-bottom: 6px;
    font-weight: 600;
    font-size: 13px;
    color: $body-color;
  }
  &__model {
    display: block;
    margin-bottom: 6px;
    font-size: 13px;
    color: $gray-600;
  }
  &__price {
    display: flex;
    align-items: flex-end;
    font-size: 14px;
    font-weight: 800;
    color: $primary;
  }
  &__priceOld {
    margin-left: 16px;
    font-weight: 500;
    font-size: 12px;
    color: $gray-600;
    text-decoration: line-through;
  }
}

// Navbar
.navbar-nav {
  > li {
    &:hover {
      .navbar-nav__collapse {
        opacity: 1;
        visibility: visible;
      }
      .navbar-nav__wrapper {
        background: $gray-200;
        border-radius: 4px;
        color: $primary;
      }
    }
    + li {
      border-top: 1px solid $gray-300;
    }

    @include media-breakpoint-down(lg) {
      .navbar-nav__link[data-bs-toggle="collapse"]:not(.collapsed) {
        background: $gray-200;
        border-radius: 4px;
        color: $primary;
        .icon {
          transform: rotate(90deg);
        }
      }
    }
  }

  @include media-breakpoint-up(lg) {
    &__wrapper {
      position: relative;
      .navbar-nav__link[data-bs-toggle="collapse"] {
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        pointer-events: none;
      }
    }
    &__link {
      padding-right: 30px;
    }
  }

  &__link {
    display: flex;
    align-items: center;
    padding: 0.375rem 0.75rem;
    font-weight: 600;
    font-size: 15px;
    color: $gray-800;
    text-decoration: none;
    line-height: 1.2;
    transition: $btn-transition;
    @include media-breakpoint-down(lg) {
      .icon {
        @include transition(transform 0.2s linear);
      }
    }
  }

  > li:hover > .navbar-nav__link {
    color: $secondary;
  }

  &__arrow {
    margin-left: auto;
    margin-right: 0;
    font-size: 14px;
  }

  &__collapse {
    @include media-breakpoint-up(lg) {
      display: block !important;
      position: absolute;
      background: #fff;
      opacity: 0;
      visibility: hidden;
      top: 0;
      width: 720px;
      height: 100%;
      left: 240px;
      transition: opacity 0.3s ease-out 0.05s, visibility 0.3s ease-out 0.05s;
      overflow: hidden;
      z-index: 15;
    }
    @include media-breakpoint-up(xl) {
      left: 310px;
      width: 930px;
    }
  }
}

.navbar-children {
  padding: 1rem 0 1rem 1rem;
  @include media-breakpoint-up(lg) {
    overflow: hidden;
    overflow-y: auto;
    height: 100%;
  }
  &__link {
    display: flex;
    align-items: center;
    padding: 0.25rem 0.5rem;
    font-weight: 600;
    font-size: 15px;
    color: $gray-800;
    text-decoration: none;
    line-height: 1.2;
    @include media-breakpoint-up(lg) {
      display: block;
      padding: 1rem 0.5rem;
      text-align: center;
    }
    @include media-breakpoint-down(lg) {
      img {
        max-width: 32px;
      }
    }

    span {
      display: block;
    }

    &:hover {
      background: $gray-200;
      border-radius: 4px;
      color: $primary;
    }
  }

  @include media-breakpoint-down(lg) {
    .col + .col .navbar-children__link {
      margin-top: 0.25rem;
    }
  }
}
