.s-categories {
  line-height: 1;
  &__image {
    max-width: 36%;
  }
  &__name {
    min-height: 28px;
    margin-bottom: 12px;
    font-weight: 600;
    font-size: 14px;
    color: $white;
  }
  &__from {
    margin-bottom: 12px;
    font-weight: 600;
    font-size: 14px;
    color: $gray-400;
  }
  &__detail {
    display: flex;
    align-items: center;
    font-size: 12px;
  }
  @include media-breakpoint-up(md) {
    &__name {
      min-height: 32px;
      font-size: 16px;
      margin-bottom: 16px;
    }
    &__from {
      font-size: 15px;
      margin-bottom: 16px;
    }
    &__detail {
      font-size: 13px;
    }
  }
}

.s-products {
  .card-img-top {
    text-align: center;
  }
  &__title {
    display: block;
    margin-top: 0.75em;
    margin-bottom: 0.5rem;
    font-weight: 600;
    font-size: 12px;
    line-height: 1;
    text-decoration: none;
    min-height: 48px;
    &:hover {
      color: $gray-700;
    }
    @include media-breakpoint-up(sm) {
      font-size: 14px;
      min-height: 42px;
    }
  }
  &__model {
    font-weight: 400;
    font-size: 12px;
    color: $gray-400;
  }
  &__price {
    font-weight: 700;
    font-size: 16px;
    color: $secondary;
  }
  &__old {
    font-weight: 500;
    font-size: 14px;
    color: $gray-800;
    text-decoration: line-through;
  }
  &__special,
  &__price {
    line-height: 17px;
    // @include media-breakpoint-down(sm) {
    //   flex-wrap: wrap;
    //   min-height: 34px;
    //   .s-products__price {
    //     min-height: auto;
    //   }
    // }
  }
  &__noprice {
    font-weight: bold;
    line-height: 17px;
    // @include media-breakpoint-down(sm) {
    //   line-height: 34px;
    // }
  }
  @include media-breakpoint-down(sm) {
    &__old,
    &__noprice {
      font-size: 12px;
    }
    &__special,
    &__price {
      font-size: 14px;
      white-space: nowrap;
    }
  }
  &__btn {
    display: inline-flex;
    align-items: center;
    @include media-breakpoint-down(sm) {
      padding: 5px;
      font-size: 13px;
    }
  }
  &__icon {
    padding: 4px;
    font-size: 20px;
    width: 28px;
    height: 28px;
    display: block;
    text-decoration: none;
    @include media-breakpoint-up(sm) {
      font-size: 22px;
      width: 32px;
      height: 32px;
    }
  }
  @include media-breakpoint-down(sm) {
    &__icons {
      margin-right: -4px;
    }
  }
  &__status {
    margin-top: 4px;
    font-size: 12px;
    color: $gray-700;
    line-height: 1;
  }
  &__ready {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    text-decoration: none;
    font-weight: 600;
    font-size: 12px;
    line-height: 1;
    @include media-breakpoint-up(md) {
      font-size: 14px;
    }
  }
  &__labels {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    z-index: 5;
  }
  &__label {
    margin-bottom: 2px;
    background-color: $gray-500;
    border-radius: 50px;
    padding: 3px 10px;
    font-weight: 600;
    font-size: 12px;
    color: $white;
    line-height: 1;
    @include media-breakpoint-up(sm) {
      margin-bottom: 5px;
      padding: 5px 10px;
      font-size: 14px;
    }
  }
  &__label-new {
    background-color: $green;
  }
  &__label-video {
    background-color: #fa8f18;
    span {
      display: none;
    }
    @include media-breakpoint-up(md) {
      .icon {
        display: none;
      }
      span {
        display: block;
      }
    }
  }
  &__label-best {
    background-color: #1f45b2;
  }
  &__label-top {
    background-color: #fbcb21;
  }
  &__label-svg {
    margin-right: 4px;
  }
}
.s-timer {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  z-index: 7;
  padding: 5px;
  &__text {
    font-size: 12px;
  }
  &__countdown {
    font-size: 12px;
  }
  @include media-breakpoint-up(sm) {
    & {
      padding: 10px;
    }
    &__text {
      font-size: 14px;
    }
    &__countdown {
      font-size: 14px;
    }
  }
}
.s-timer,
.s-pageTimer {
  background-color: #f02323;
  color: $white;
  border-radius: 10px;
  text-align: left;
  line-height: 1;
  &__content {
    line-height: 1.25;
  }
  &__text {
    display: block;
    font-weight: 500;
  }
  &__countdown {
    display: block;
    font-weight: 700;
    white-space: nowrap;
  }
  &__icon {
    font-size: 28px;
    .icon {
      display: block;
    }
  }
  @include media-breakpoint-up(sm) {
    &__icon {
      font-size: 32px;
    }
  }
}
.s-pageTimer {
  padding: 10px;
  &__text {
    font-size: 14px;
  }
  &__countdown {
    font-size: 16px;
  }
  @include media-breakpoint-up(sm) {
    &__text {
      font-size: 17px;
    }
    &__countdown {
      font-size: 20px;
    }
  }
}
